<script setup>
import AuthorCard from '@/components/AuthorCard.vue';
import BlogShareButtons from './BlogShareButtons.vue';

const props = defineProps({
  strapi: {
    type: Object,
    required: true,
    default: () => {}
  },
  authors: {
    type: Array,
    required: true,
    default: () => []
  }
});
</script>

<template>
  <div class="container grid pt-15">
    <div class="grid-item -col-span-12 -col-span-md-10 -col-start-md-2 -col-span-lg-8 -col-start-lg-3 -col-span-xl-6 -col-start-xl-4">
      <VImg v-if="props.strapi?.Image" class="rounded-lg w-100 mt-6 mx-auto" :aspect-ratio="2.54" :src="props.strapi?.Image.url" :alt="props.strapi?.Image.alternativeText" />
      <div class="w-100 mx-auto">
        <h1 class="text-secondary mt-6 -no-margin">{{ props.strapi?.Title }}</h1>
        <span v-if="props.strapi?.PublicationDate" class="paragraph-large -no-margin mt-n2 text-grey max-w-1060px">{{ $utils.formatDateAsDayMonthYear(props.strapi?.PublicationDate) }}</span>
        <div v-if="props.authors" class="d-flex flex-wrap ga-4 ga-md-8 mt-6">
          <div v-for="author in props.authors" :key="author.id">
            <AuthorCard :author="author.attributes" />
          </div>
        </div>
        <BlogShareButtons v-if="props.strapi?.ShareButtons" class="mt-6" :strapi="props.strapi.ShareButtons" :title="props.strapi?.Title" />
        <p v-if="props.strapi?.Type" class="type-tag" :class="{ 'text-accent technicalBg': props.strapi?.Type === 'Technical', 'text-primary generalBg': props.strapi?.Type === 'General', 'text-secondary productBg': props.strapi?.Type === 'Product' }">{{ props.strapi?.Type }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-container {
  aspect-ratio: 2.54 / 1;
}
</style>
