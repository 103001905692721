<template>
  <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex mb-3">
      <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" alt="logo lookin" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
    </header>
    <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
    <Form :disabled="loading" grid :isEditing="isEditing" :columns="isEditing ? '1fr' : '1fr 1fr'" @submit.prevent="save">
      <template #section1>
        <label for="companyInformationName">{{ strapi.CompanyNameLabel }}</label>
        <TextField v-if="isEditing" id="companyInformationName" v-model="customer.name" name="companyInformation.name" :disabled="customer.isPushedToBC" />
        <span v-else>{{ customer.name }}</span>
        <label for="companyInformationWeb">{{ strapi.WebsiteLabel }}</label>
        <WebsiteTextField v-if="isEditing" id="companyInformationWeb" v-model="customer.web" name="companyInformation.web" />
        <span v-else>
          <a :href="customer.web" target="_blank">{{ customer.web }}</a>
        </span>
        <label for="companyInformationPhone">{{ strapi.CompanyPhoneLabel }}</label>
        <TelInput v-if="isEditing" id="companyInformationPhone" v-model="customer.phone" name="companyInformation.phone" :placeholder="strapi.PhonePlaceholder" :defaultCountry="$country.code" />
        <span v-else>{{ customer.phone }}</span>
        <label for="companyInformationMainContactEmail">{{ strapi.MainContactEmailLabel }}</label>
        <EmailTextField v-if="isEditing" id="companyInformationMainContactEmail" v-model="customer.main_contact_email" name="companyInformation.main_contact_email" />
        <span v-else>{{ customer.main_contact_email }}</span>
        <label>{{ strapi.PrimaryMarketLabel }}</label>
        <Select v-if="isEditing" id="companyInformationPrimaryMarket" v-model="customer.primary_market" :items="$controller.data.customerPrimaryMarketOptions" />
        <span v-else>{{ $controller.data.customerPrimaryMarketOptions[customer.primary_market] }}</span>
        <label for="companyInformationActivityDescription">{{ strapi.ActivityDescriptionLabel }}</label>
        <Textarea v-if="isEditing" id="companyInformationActivityDescription" v-model="customer.activity_description" auto-grow />
        <div v-else v-markdown="customer.activity_description" />
      </template>
      <template #section2>
        <label>{{ strapi.RegistrationDateLabel }}</label>
        <span>{{ $utils.date(customer.created_at, 'full') }}</span>
        <label>{{ strapi.ConfirmationDateLabel }}</label>
        <span>{{ $utils.date(customer.confirmed_at, 'full') }}</span>
      </template>
      <template #actions>
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <footer class="d-flex" :class="{ 'flex-column': isMobile }">
          <template v-if="isEditing">
            <Button color="secondary" variant="outlined" :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" :loading="loading" :disabled="loading" type="submit">
              {{ strapi.SaveButtonLabel }}
            </Button>
            <Button color="secondary" variant="outlined" :disabled="loading" :block="isMobile" @click="cancel">Cancel</Button>
          </template>
          <template v-else>
            <Button v-if="!applyForInvoicePaymentIsOpen" :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" color="secondary" variant="outlined" :disabled="editingIsDisabled" :prependIcon="strapi.EditButtonIcon" @click="edit">
              {{ strapi.EditButtonLabel }}
            </Button>
            <Button v-if="!applyForInvoicePaymentIsOpen && !customerInvoiceCountryIsUSA" :href="strapi.InvoicePaymentButtonHref" color="secondary" :block="isMobile" variant="outlined" :disabled="editingIsDisabled" :prependIcon="strapi.InvoicePaymentButtonIcon" @click="openApplyForInvoicePayment">
              {{ strapi.InvoicePaymentButtonLabel }}
            </Button>
          </template>
        </footer>
      </template>
    </Form>
    <ApplyForInvoicePayment v-if="applyForInvoicePaymentIsOpen && !customerInvoiceCountryIsUSA" :customer="customer" @onCloseApplyForInvoicePayment="closeApplyForInvoicePayment" />
  </Card>
</template>

<script>
import TelInput from '@/components/TelInput.vue';
import ApplyForInvoicePayment from './ApplyForInvoicePayment.vue';
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'CompanyInformation',
  components: { ApplyForInvoicePayment, TelInput },
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customer: { ...this.modelValue },
      loading: false,
      applyForInvoicePaymentIsOpen: false,
      invoiceAddress: '',
      financialContact: '',
      financialEmail: ''
    };
  },
  computed: {
    customerInvoiceCountryIsUSA() {
      return this.customer.customerInvoiceAddress?.country?.code === 'US';
    }
  },
  methods: {
    async save() {
      this.loading = true;
      const response = await this.put('company-information', { companyInformation: { ...this.customer } });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    edit() {
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.customer = { ...this.modelValue };
    },
    openApplyForInvoicePayment() {
      this.applyForInvoicePaymentIsOpen = true;
    },
    closeApplyForInvoicePayment() {
      this.applyForInvoicePaymentIsOpen = false;
    }
  }
};
</script>
