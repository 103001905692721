<template>
  <div class="pt-15 container grid bg-white align-center">
    <div class="grid-item -col-span-12 -col-span-md-10 -col-start-md-2 -col-span-lg-8 -col-start-lg-3 -col-span-xl-6 -col-start-xl-4">
      <h1 class="text-secondary mw-655px text-center pl-5 pr-5 mt-6">{{ props.title }}</h1>
      <VImg v-if="props.image" class="rounded-lg mh-560px" width="1060" cover :src="props.image.url" :alt="props.image.alternativeText" />
      <div v-if="props.description" v-markdown="props.description" class="pt-20 pb-20 paragraph-large mw-720px richText" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
    default: () => null
  },
  image: {
    type: Object,
    required: false,
    default: () => null
  },
  description: {
    type: String,
    required: false,
    default: ''
  }
});
</script>

<style lang="scss" scoped>
.type-tag {
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
}
.technicalBg {
  background: rgba($color-accent, 0.1);
}
.generalBg {
  background: rgba($color-primary, 0.1);
}
.productBg {
  background: rgba($color-secondary, 0.1);
}
</style>
