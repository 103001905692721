<template>
  <div
    class="container pt-15"
    :class="{
      'pb-6 pb-lg-10': strapi.VideoSize === 'Small',
      'pb-8 pb-lg-16': strapi.VideoSize !== 'Small'
    }"
  >
    <Card
      class="overflow-visible"
      :class="{
        'bg-secondary': strapi.BackgroundColor === 'Dark' && !strapi.VideoOutsideContainer,
        'bg-white': strapi.BackgroundColor === 'Light' && !strapi.VideoOutsideContainer,
        'bg-transparent': strapi.BackgroundColor === 'None' || strapi.VideoOutsideContainer
      }"
    >
      <div class="grid">
        <div
          class="grid-item -col-span-12"
          :class="{
            '-col-span-md-4': strapi.VideoSize === 'Small',
            '-col-span-md-6': strapi.VideoSize !== 'Small',
            'align-right': strapi.PlacementVideo === 'Right',
            'y-padding-top': strapi.BackgroundColor !== 'None',
            'x-padding-right': strapi.PlacementVideo === 'Right' && strapi.BackgroundColor !== 'None',
            'x-padding-left': strapi.PlacementVideo !== 'Right' && strapi.BackgroundColor !== 'None'
          }"
        >
          <video v-if="strapi.Video.VideoFile" class="w-100 rounded-lg elevation-21" :controls="strapi.Video.ShowControls" :autoplay="strapi.Video.AutoPlay" :muted="strapi.Video.AutoPlay || strapi.Muted" :poster="strapi.Video.PosterImage?.url || ''" :loop="strapi.Video.Loop">
            <source :src="strapi.Video.VideoFile.url" />
          </video>
        </div>
        <div
          ref="textBlockRef"
          class="text-block grid-item -col-span-12 d-flex flex-column"
          :class="{
            '-col-span-md-8': strapi.VideoSize === 'Small',
            '-col-span-md-6 ': strapi.VideoSize !== 'Small',
            'y-padding-bottom': strapi.BackgroundColor !== 'None',
            'x-padding-left': strapi.PlacementVideo === 'Right' && strapi.BackgroundColor !== 'None',
            'x-padding-right': strapi.PlacementVideo !== 'Right' && strapi.BackgroundColor !== 'None',
            'justify-center': strapi.BackgroundColor === 'None',
            'pr-md-4 pr-lg-10': strapi.PlacementVideo === 'Right' && strapi.BackgroundColor === 'None',
            'pl-md-4 pl-lg-10': strapi.PlacementVideo !== 'Right' && strapi.BackgroundColor === 'None',
            'h-100': !strapi.VideoOutsideContainer
          }"
        >
          <span
            v-if="strapi.VideoOutsideContainer && strapi.BackgroundColor !== 'None'"
            ref="textBgRef"
            class="text-block-bg rounded-lg"
            :class="{
              'bg-secondary': strapi.BackgroundColor === 'Dark',
              'bg-white': strapi.BackgroundColor === 'Light'
            }"
          />
          <div class="mb-5">
            <h2 class="h1" :class="{ 'text-secondary': strapi.BackgroundColor !== 'Dark', 'text-white': strapi.BackgroundColor === 'Dark' }">{{ strapi.Title }}</h2>
            <p class="paragraph-large" :class="{ 'text-black': strapi.BackgroundColor !== 'Dark', 'text-white': strapi.BackgroundColor === 'Dark' }">{{ strapi.Text }}</p>
          </div>
          <div v-for="link in strapi.Link" :key="link" class="mb-3" :class="{ 'd-flex flex-1-0 align-end': strapi.BackgroundColor !== 'None' }">
            <Button v-if="link.Href" icon="fa-regular fa-arrow-right" iconFirst iconColor="primary" variant="plain" :href="link.Href" :color="strapi.BackgroundColor === 'Dark' ? 'white' : 'black'">{{ link.LinkLabel }}</Button>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { throttle } from 'throttle-debounce';


const props = defineProps({
  strapi: {
    type: Object,
    default: () => {},
    required: true
  }
});

onMounted(() => {
  setBgHeight();
  window.addEventListener('resize', throttledEventListener);
});
onUnmounted(() => {
  window.removeEventListener('resize', throttledEventListener);
});

function setBgHeight() {
  const textBgRef = ref(null);

  if (!textBgRef.value) return;
  let bgHeight = 0;
  if (window.innerWidth > 959) {
    bgHeight = `${textBlockRef.value.clientHeight}px`;
  } else {
    bgHeight = '100%';
  }
  textBgRef.value.style.height = bgHeight;
}

function throttledEventListener() {
  throttle(300, this.setBgHeight());
}
</script>

<style lang="scss" scoped>

.video-card {
  -webkit-box-sizing: unset;
  -moz-box-sizing: unset;
  box-sizing: unset;
}
.align-right {
  @include is-screen-md() {
    order: 2;
  }
}

.text-block-bg {
  position: absolute;
  width: 100%;
  display: block;
  z-index: -1;
  left: 0;
  top: 0;
}
.y-padding-top {
  padding-top: 24px;

  @include is-screen-md() {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include is-screen-lg() {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.y-padding-bottom {
  padding-bottom: 24px;

  @include is-screen-md() {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @include is-screen-lg() {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
.x-padding-right {
  padding-right: 24px;
  padding-left: 24px;

  @include is-screen-md() {
    padding-right: 40px;
    padding-left: 8px;
  }

  @include is-screen-lg() {
    padding-right: 80px;
    padding-left: 20px;
  }
}

.x-padding-left {
  padding-left: 24px;
  padding-right: 24px;

  @include is-screen-md() {
    padding-left: 40px;
    padding-right: 8px;
  }

  @include is-screen-lg() {
    padding-left: 80px;
    padding-right: 20px;
  }
}
</style>
