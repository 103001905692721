<template>
  <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex">
      <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
    </header>
    <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
    <Form :disabled="loading" grid :isEditing="isEditing" :columns="isEditing ? '1fr' : '1fr 1fr'" @submit.prevent="save">
      <template #section1>
        <label for="accountInformationName">{{ strapi.NameLabel }}</label>
        <TextField v-if="isEditing" id="accountInformationName" v-model="user.name" name="accountInformation.name" />
        <span v-else>{{ user.name }}</span>
        <label for="accountInformationPosition">{{ strapi.PositionLabel }}</label>
        <TextField v-if="isEditing" id="accountInformationPosition" v-model="user.position" name="accountInformation.position" />
        <span v-else>{{ user.position }}</span>
        <label for="accountInformationEmail">{{ strapi.EmailLabel }}</label>
        <EmailTextField v-if="isEditing" id="accountInformationEmail" v-model="user.email" name="accountInformation.email" />
        <span v-else>{{ user.email }}</span>
        <label for="accountInformationPhone">{{ strapi.PhoneLabel }}</label>
        <TelInput v-if="isEditing" id="AccountInformationPhone" v-model="user.phone" name="accountInformation.phone" :placeholder="strapi.PhonePlaceholder" :defaultCountry="$country.code" />
        <span v-else>{{ user.phone }}</span>
        <label for="accountInformationPhone">{{ strapi.MobilePhoneLabel }}</label>
        <TelInput v-if="isEditing" id="accountInformationMobile" v-model="user.mobile" name="accountInformation.mobile" :placeholder="strapi.PhonePlaceholder" :defaultCountry="$country.code" />
        <span v-else>{{ user.mobile }}</span>
      </template>
      <template #section2>
        <label>{{ strapi.AccountCreatedLabel }}</label>
        <span>{{ $utils.date(user.created_at, 'full') }}</span>
        <label>{{ strapi.ActivationMailLabel }}</label>
        <span>{{ $utils.date(user.activation_mail_sent_at, 'full') }}</span>
        <label>{{ strapi.ActivatedLabel }}</label>
        <span>{{ $utils.date(user.activated_at, 'full') }}</span>
        <label for="userMessageTimeout">{{ strapi.DisplayInfoMessagesLabel }}</label>
        <SelectBoolean v-if="isEditing" id="userMessageTimeout" :model-value="user.message_timeout ? false : true" name="accountInformation.message_timeout" @update:modelValue="user.message_timeout = !$event" />
        <span v-else>{{ user.message_timeout ? 'No' : 'Yes' }}</span>
      </template>
      <template #actions>
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <footer class="d-flex" :class="{ 'flex-column': isMobile }">
          <template v-if="isEditing">
            <Button color="secondary" variant="outlined" :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" :loading="loading" :disabled="loading" type="submit">
              {{ strapi.SaveButtonLabel }}
            </Button>
            <Button color="secondary" variant="outlined" :block="isMobile" :disabled="loading" @click="cancel">Cancel</Button>
          </template>
          <Button v-else color="secondary" variant="outlined" :block="isMobile" :disabled="editingIsDisabled" :prependIcon="strapi.EditButtonIcon" @click="edit">
            {{ strapi.EditButtonLabel }}
          </Button>
        </footer>
      </template>
    </Form>
  </Card>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';
import TelInput from '@Components/TelInput.vue';

export default {
  name: 'AccountInformation',
  components: { TelInput },
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: { ...this.modelValue },
      loading: false
    };
  },
  methods: {
    async save() {
      this.loading = true;
      const response = await this.put('account-information/', { accountInformation: { ...this.user } });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    edit() {
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.user = { ...this.modelValue };
    }
  }
};
</script>
