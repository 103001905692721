<script setup>
import NewsCustomerHero from '@/components/NewsCustomerHero.vue';
import { useWindowTechship } from '@/composables/useWindowTechship';

const { data } = useWindowTechship();
</script>

<template>
  <Card class="mt-20">
    <NewsCustomerHero :title="data.news.Title" :image="data.news.Image" :markdown="data.news.Description" />
    <PageBuilder v-if="data.strapi?.Modules" :modules="data.strapi?.Modules" />
  </Card>
</template>
