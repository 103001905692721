<template>
  <div class="bg-grey-background" :data-menu-bg="disabledHeroCheck ? false : isFirstModulesComponentPageHeroLight || isMissingHero">
    <section v-for="block in modules" :key="block.id" class="">
      <HeroDark v-if="block.__component === 'page-builder.page-hero'" :strapi="block" />
      <HeroLight v-if="block.__component === 'page-builder.page-hero-light'" :strapi="block" />
      <OurFocus v-if="block.__component === 'page-builder.our-focus'" :strapi="block" />
      <BoxLinks v-if="block.__component === 'page-builder.box-links'" :strapi="block" />
      <SingleImage v-if="block.__component === 'page-builder.image'" :strapi="block" />
      <SingleVideo v-if="block.__component === 'page-builder.video'" :strapi="block" />
      <ContentList v-if="block.__component === 'page-builder.content-list'" :strapi="block" />
      <SelectedContent v-if="block.__component === 'page-builder.selected-content'" :strapi="block" />
      <RichText v-if="block.__component === 'page-builder.rich-text'" :strapi="block" />
      <CarouselLogos v-if="block.__component === 'page-builder.carousel-logos'" :strapi="block" />
      <CarouselText v-if="block.__component === 'page-builder.carousel-text'" :strapi="block" />
      <ProductCategories v-if="block.__component === 'page-builder.product-categories'" :strapi="block" />
      <ProductCategoriesList v-if="block.__component === 'page-builder.product-categories-list'" :strapi="block" />
      <ImageText v-if="block.__component === 'page-builder.image-text'" :strapi="block" />
      <Quote v-if="block.__component === 'page-builder.quote'" :strapi="block" />
      <ProductListing v-if="block.__component === 'page-builder.product-listing'" :data="block" />
      <ProductImageCarousel v-if="block.__component === 'page-builder.product-image-carousel'" :strapi="block" />
      <Contact v-if="block.__component === 'page-builder.contact-us'" :strapi="block" />
      <SingleButton v-if="block.__component === 'page-builder.button'" :strapi="block" />
      <ProductHero v-if="block.__component === 'page-builder.product'" :strapi="block" />
      <ImageRichText v-if="block.__component === 'page-builder.image-and-rich-text'" :strapi="block" />
      <Grid v-if="block.__component === 'page-builder.grid'" :strapi="block" />
      <StrapiSpacer v-if="block.__component === 'page-builder.spacer'" :strapi="block" />
      <GeographicalMap v-if="block.__component === 'page-builder.geographical-map'" :strapi="block" />
      <ShortBoxLinks v-if="block.__component === 'page-builder.short-box-links'" :strapi="block" />
      <HighlightRow v-if="block.__component === 'page-builder.highlight-row'" :strapi="block" />
      <TeamSection v-if="block.__component === 'page-builder.team-section'" :strapi="block" v-bind="$attrs" />
      <BlogImage v-if="block.__component === 'page-builder.blog-image'" :strapi="block" />
      <VideoText v-if="block.__component === 'page-builder.video-text'" :strapi="block" />
    </section>
  </div>
</template>

<script>
import OurFocus from '@Components/OurFocus.vue';
import Grid from '@Components/Grid.vue';
import BoxLinks from '@Components/BoxLinks.vue';
import HeroDark from '@Components/HeroDark.vue';
import HeroLight from '@Components/HeroLight.vue';
import SingleImage from '@Components/SingleImage.vue';
import SingleVideo from '@Components/SingleVideo.vue';
import ContentList from '@Components/ContentList.vue';
import SelectedContent from '@Components/SelectedContent.vue';
import RichText from '@Components/RichText.vue';
import CarouselLogos from '@Components/CarouselLogos.vue';
import CarouselText from '@Components/CarouselText.vue';
import ImageText from '@Components/ImageText.vue';
import Quote from '@Components/Quote.vue';
import ProductImageCarousel from '@Components/ProductImageCarousel.vue';
import Contact from '@Components/Contact.vue';
import ProductHero from '@Components/ProductHero.vue';
import ProductCategories from '@Components/ProductCategories.vue';
import ProductCategoriesList from '@Components/ProductCategoriesList.vue';
import ProductListing from '@Components/ProductListing.vue';
import SingleButton from '@Components/SingleButton.vue';
import ImageRichText from '@Components/ImageRichText.vue';
import StrapiSpacer from '@Components/StrapiSpacer.vue';
import GeographicalMap from '@Components/GeographicalMap.vue';
import ShortBoxLinks from '@Components/ShortBoxLinks.vue';
import TeamSection from '@Components/TeamSection.vue';
import HighlightRow from '@/components/HighlightRow.vue';
import BlogImage from '@/components/BlogImage.vue';
import VideoText from '@Components/VideoText.vue';

export default {
  components: { HighlightRow, OurFocus, BoxLinks, ContentList, SelectedContent, HeroDark, HeroLight, SingleImage, SingleVideo, RichText, CarouselLogos, CarouselText, ImageText, Quote, ProductImageCarousel, Contact, ProductHero, ProductCategories, ProductCategoriesList, ProductListing, SingleButton, ImageRichText, Grid, StrapiSpacer, GeographicalMap, ShortBoxLinks, TeamSection, VideoText, BlogImage },
  props: {
    modules: {
      required: true
    },
    disabledHeroCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMissingHero() {
      if (this.$isEmpty(this.modules)) return true;
      // Define hero components
      const heroComponents = ['page-builder.page-hero', 'page-builder.page-hero-light', 'page-builder.product'];
      // Get the first module's component
      const firstModuleComponent = this.$first(this.modules).__component;
      // Return true if the first module's component is not a hero component
      return !this.$includes(heroComponents, hero => hero === firstModuleComponent);
    },
    isFirstModulesComponentPageHeroLight() {
      return this.$first(this.modules)?.__component === 'page-builder.page-hero-light';
    }
  }
};
</script>
