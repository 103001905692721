<script setup>
import NewsCustomerHero from '@/components/NewsCustomerHero.vue';
import { useWindowTechship } from '@/composables/useWindowTechship';

const { data } = useWindowTechship();
</script>

<template>
  <Card class="mt-20" data-menu-bg="true">
    <NewsCustomerHero :title="data.strapi.Title" :image="data.strapi.Image" :description="data.strapi.Introduction" />
    <PageBuilder v-if="data.strapi.Modules" :modules="data.strapi.Modules" />
  </Card>
</template>
