<script setup>
import { ref } from 'vue';

const props = defineProps({
  strapi: {
    type: Object,
    required: true,
    default: () => {}
  }
});

const Image = ref(props.strapi.Image[0]);
</script>

<template>
  <div class="container grid">
    <div class="grid-item -col-span-12 -col-span-md-10 -col-start-md-2 -col-span-lg-8 -col-start-lg-3 -col-span-xl-6 -col-start-xl-4">
      <div v-if="props.strapi.PreHeader" class="d-flex">
        <p class="text-primary pr-3 paragraph-large text-align">|</p>
        <p class="pre-header text-align">{{ props.strapi.PreHeaderText }}</p>
      </div>
      <figure class="d-flex flex-column">
        <Card>
          <VImg :src="Image.url" :lazy-src="Image.formats.small.url" :aspect-ratio="Image.width / Image.height" />
        </Card>
        <figcaption v-if="props.strapi.Caption" class="mx-auto">{{ strapi.Caption }}</figcaption>
      </figure>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.figcaption:before {
  content: '';
  display: block;
  width: 2px;
  height: 27px;
  background: $color-primary;
  margin-right: 24px;
}
.pre-header {
  font-size: 1.5rem;
}
</style>
